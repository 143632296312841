import React from 'react'
import Main from './components/MainComponent/Main';


function App() {
  return (
    <div className="App">  
      <Main/>   
    </div>
  );
}

export default App;
